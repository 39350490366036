<template>
  <!--最近更新-->
  <div class="Help">
    <div class="Help-box">
      <div class="Help-content">
        <ul class="help-content-flex-father">
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/wode1301.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_1)}}</h3>
              <p> 
               {{getString(Strings.Recently_Updated_Content_1)}}
              </p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/muban0249.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_2)}}</h3>
              <p>{{getString(Strings.Recently_Updated_Content_2)}}</p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/pingtai0345.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_3)}}</h3>
              <p>
                {{getString(Strings.Recently_Updated_Content_3)}}
              </p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/xijie0400.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_4)}}</h3>
              <p>{{getString(Strings.Recently_Updated_Content_4)}}</p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/shiwu0716.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_5)}}</h3>
              <p>{{getString(Strings.Recently_Updated_Content_5)}}</p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/shuchu0685.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_6)}}</h3>
              <p>
                {{getString(Strings.Recently_Updated_Content_6)}}
              </p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/yun1144.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_7)}}</h3>
              <p>{{getString(Strings.Recently_Updated_Content_7)}}</p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/shisanz0547.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Recently_Updated_Title_8)}}</h3>
              <p>{{getString(Strings.Recently_Updated_Content_8)}}
              </p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/group_introduce.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Mind_Edit_Page_Sharing_Group)}}</h3>
              <p>{{getString(Strings.Mind_Edit_Page_Sharing_Group_introduce)}}
              </p>
            </div>
          </li>
          <li>
            <div class="help-img">
              <img src="../../assets/img/user/Iitem0selected/playbackMap10.png" alt="">
            </div>
            <div class="help-zi">
              <h3>{{getString(Strings.Mindmap_Review)}}</h3>
              <p>{{getString(Strings.Mindmap_Review_Recently_Updated_Content_10)}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import unifildStyle from "../../assets/css/help/unifildStyle.less";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
export default {
  components: {},
  data() {
    return {Strings:Strings};
  },
  created() {},
  mounted() {},
  methods: {
    getString(i){
     return getString(i)
    },
    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
ul li {
  list-style: none;
  
}

.help-content-flex-father{
  display: flex;
  flex-wrap: wrap;
}

.Help-content h3 {
  color: #Ff7354;
  margin-bottom: 10px;
  font-family: Noto Sans SC;
  font-size: 18px;
}
.Help-content ul{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Help-content ul li{
  color: #666;
  font-family: Noto Sans SC;
  font-size: 16px;
  /* line-height: 30px; */
  list-style: none;
  width: 42%;
  /* float: left; */
  display: flex;
  margin-top: 2em;
  margin-right: 2em;
}
.Help-content .help-img{
  margin-right: 10px;
}
.Help-content .help-img img{
  width: 170px;
}
.Help-content .help-zi{
  padding-top: 1em;
  width: 58%;
}
</style>